import { Component, Input, EventEmitter } from '@angular/core';
import { UtilService } from 'fx-core';
import { BsModalRef } from 'ngx-bootstrap';
import { CommonPortletService } from '../services/commonportlet.service';
import * as moment from 'moment';

@Component({
    selector: 'admission-fee-modal',
    templateUrl: 'admission-fee-modal.component.html',
    styleUrls: ['admission-fee-modal.component.scss']
})

export class AdmissionFeeComponent {
    @Input() dropdown: any;
    @Input() amount: any;
    @Input() feesData: any;
    enablePay: boolean = false;
    paymentMode: number = 1;
    remarks: string;
    invoiceDate: any;
    invoice: string = '';
    public refreshData: EventEmitter<any> = new EventEmitter();

    constructor(protected utl: UtilService, private modalRef: BsModalRef, private api: CommonPortletService) {

    }

    ngOnInit() {
        this.invoice = this.feesData && this.feesData.invoiceNo ? this.feesData.invoiceNo : '';
        this.invoiceDate = this.feesData && this.feesData.invoiceDate ? this.feesData.invoiceDate : '';
        this.paymentMode = this.feesData && this.feesData.paymentMethod ? this.feesData.paymentMethod : 1
        this.remarks = this.feesData && this.feesData.remarks ? this.feesData.remarks : ''

        if (this.feesData && !this.feesData.invoiceNo) {
            this.enablePay = true;
        }
    }

    payFee() {
        const body = { ...this.feesData };
        body.remarks = this.remarks;
        body.paymentMethod = this.paymentMode;
        body.invoiceNo = 'ADF' + Date.now();
        body.invoiceDate = moment().format("YYYY-MM-DD");
        console.log(body);
        this.api.saveData({ data: body }, 'student/studentAdmissionFee/updateStudentAdmissionFee').then(fee => {
            this.refreshData.emit({ refresh: true });
            this.modalRef.hide();
        }, error => {
            console.log(error);
        });
    }

}
