import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { allmenu } from '../../routes/menu';
declare var $: any;

import { MenuService } from '../../core/menu/menu.service';
import { SettingsService } from '../../core/settings/settings.service';
import { UtilService, MessageService } from 'fx-core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

    menuItems: Array<any>;
    router: Router;
    sbclickEvent = 'click.sidebar-toggle';
    $doc: any = null;
    subscription: Subscription;

    constructor(public menu: MenuService, public settings: SettingsService, public injector: Injector,
        public utl: UtilService, public messageService: MessageService) {

        //this.menuItems = menu.getMenu();

        this.subscription = this.messageService.getMessage().subscribe(res => {
            if (res && res.type == 'menurefresh') {
                this.loadMenus();
            }
        });
    }

    ngOnInit() {
        this.router = this.injector.get(Router);
        this.toggleCollapsedSideabar(); // close when load first

        this.loadMenus();


        this.router.events.subscribe((val) => {
            // close any submenu opened when route changes
            this.removeFloatingNav();
            // scroll view to top
            window.scrollTo(0, 0);
            // close sidebar on route change
            this.settings.setLayoutSetting('asideToggled', false);
        });

        // enable sidebar autoclose from extenal clicks
        this.anyClickClose();

        // setTimeout(() => {
        //     console.log('--->');
        //     this.toggleCollapsedSideabar()
        // }, 5000);

    }

    loadMenus() {
        var authToken = this.utl.session.getObject('authtoken');
        if (authToken.user.groupId == 1) {
            this.menuItems = allmenu[authToken.user.groupId];
        } else {
            this.getMenus();
        }
    }

    //get menus from database
    getMenus() {
        var options: any = {
            action: 'admin/control/getAssignedControlsForWeb',
            type: 'post',
            data: { filters: [] }
        };

        this.utl.http.doAction(options).then(data => this.getMenusCallback(data));
    }
    getMenusCallback(result) {
        // console.log('menus from db');
        // console.log(result);

        let res = result.data;
        let menus = [];
        if (res && res.length > 0) {

            //prepare parent menus
            let parentMenus = _.filter(res, function (control) {
                var result = !control.parentControlCode ? true : false;
                return result;
            });

            parentMenus = _.orderBy(parentMenus, ['displayOrder'], ['asc']);
            for (let idx in parentMenus) {
                let item = parentMenus[idx];
                let sref = item.sRef;
                let menu = {
                    controlCode: item.controlCode, text: item.display, link: sref, icon: item.iconRef, control: item,
                    submenu: [], displayOrder: item.displayOrder
                };
                menus.push(menu);
            }

            //prepare child menus
            let childMenus = _.filter(res, function (control) {
                var result = control.parentControlCode ? true : false;
                return result;
            });

            childMenus = _.orderBy(childMenus, ['displayOrder'], ['asc']);
            for (let idx in childMenus) {
                let item = childMenus[idx];
                let sref = item.sRef;

                let menu = { controlCode: item.controlCode, text: item.display, link: sref, icon: item.iconRef, control: item, displayOrder: item.displayOrder };

                let parentMenu = _.find(menus, { controlCode: item.parentControlCode });
                if (parentMenu) {
                    parentMenu.submenu = parentMenu.submenu || [];
                    parentMenu.submenu.push(menu);
                } else {
                    menus.push(menu);
                }
            }
        }
        this.menuItems = _.orderBy(menus, ['displayOrder'], ['asc']);;
    }

    navigate(event, menu) {
        event.stopPropagation();
        this.router.navigate([menu.link]);
    }

    anyClickClose() {
        this.$doc = $(document).on(this.sbclickEvent, (e) => {
            if (!$(e.target).parents('.aside-container').length) {
                this.settings.setLayoutSetting('asideToggled', false);
            }
        });
    }

    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.subscription.unsubscribe();

        if (this.$doc)
            this.$doc.off(this.sbclickEvent);
    }

    toggleSubmenuClick(event) {

        event.preventDefault();

        if (!this.isSidebarCollapsed() && !this.isSidebarCollapsedText() && !this.isEnabledHover()) {

            let ul = $(event.currentTarget.nextElementSibling);

            // hide other submenus
            let parentNav = ul.parents('.sidebar-subnav');
            $('.sidebar-subnav').each((idx, el) => {
                let $el = $(el);
                // if element is not a parent or self ul
                if (el !== parentNav[0] && el !== ul[0]) {
                    this.closeMenu($el);
                }
            });

            // abort if not UL to process
            if (!ul.length) {
                return;
            }

            // any child menu should start closed
            ul.find('.sidebar-subnav').each((idx, el) => {
                this.closeMenu($(el));
            });

            // toggle UL height
            const ulHeight = ul.css('height')
            if (ulHeight === 'auto' || parseInt(ulHeight, 10)) {
                this.closeMenu(ul);
            }
            else {
                // expand menu
                ul.on('transitionend', () => {
                    ul.css('height', 'auto').off('transitionend');
                }).css('height', ul[0].scrollHeight);
                // add class to manage animation
                ul.addClass('opening');
            }

        }

    }

    // Close menu collapsing height
    closeMenu(elem) {
        elem.css('height', elem[0].scrollHeight); // set height
        elem.css('height', 0); // and move to zero to collapse
        elem.removeClass('opening');
    }

    toggleCollapsedSideabar() {
        this.messageService.sendMessage('toggleSidebar', {});
        this.settings.toggleLayoutSetting('isCollapsed');
    }

    toggleSubmenuHover(event) {
        let self = this;
        if (this.isSidebarCollapsed() || this.isSidebarCollapsedText() || this.isEnabledHover()) {
            event.preventDefault();

            this.removeFloatingNav();

            let ul = $(event.currentTarget.nextElementSibling);
            let anchor = $(event.currentTarget);

            if (!ul.length) {
                return; // if not submenu return
            }

            let $aside = $('.aside-container');
            let $asideInner = $aside.children('.aside-inner'); // for top offset calculation
            let $sidebar = $asideInner.children('.sidebar');
            let mar = parseInt($asideInner.css('padding-top'), 0) + parseInt($aside.css('padding-top'), 0);
            let itemTop = ((anchor.parent().position().top) + mar) - $sidebar.scrollTop();

            let floatingNav = ul.clone().appendTo($aside);
            let vwHeight = document.body.clientHeight;

            // let itemTop = anchor.position().top || anchor.offset().top;

            floatingNav
                .removeClass('opening') // necesary for demo if switched between normal//collapsed mode
                .addClass('nav-floating')
                .css({
                    position: this.settings.getLayoutSetting('isFixed') ? 'fixed' : 'absolute',
                    top: itemTop,
                    bottom: (floatingNav.outerHeight(true) + itemTop > vwHeight) ? 0 : 'auto'
                });

            floatingNav
                .on('mouseleave', () => { floatingNav.remove(); })
                .find('a').on('click', function (e) {
                    e.preventDefault(); // prevents page reload on click
                    // get the exact route path to navigate
                    let routeTo = $(this).attr('route');
                    if (routeTo) self.router.navigate([routeTo]);
                });

            this.listenForExternalClicks();

        }

    }

    listenForExternalClicks() {
        let $doc = $(document).on('click.sidebar', (e) => {
            if (!$(e.target).parents('.aside-container').length) {
                this.removeFloatingNav();
                $doc.off('click.sidebar');
            }
        });
    }

    removeFloatingNav() {
        $('.nav-floating').remove();
    }

    isSidebarCollapsed() {
        return this.settings.getLayoutSetting('isCollapsed');
    }
    isSidebarCollapsedText() {
        return this.settings.getLayoutSetting('isCollapsedText');
    }
    isEnabledHover() {
        return this.settings.getLayoutSetting('asideHover');
    }

    clearNav(menu) {
        this.toggleCollapsedSideabar();
        this.utl.nav.clear();
        this.utl.nav.auditNavigation({ route: menu.link, screenCode: menu.controlCode, screen: menu.text });
    }
}
