import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
const screenfull = require('screenfull');

import { UserblockService } from '../sidebar/userblock/userblock.service';
import { SettingsService } from '../../core/settings/settings.service';
import { SessionStorageService } from 'fx-core';
import { MenuService } from '../../core/menu/menu.service';
import { UtilService, MessageService } from 'fx-core';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    navCollapsed = true; // for horizontal layout
    menuItems = []; // for horizontal layout

    isNavSearchVisible: boolean;
    @ViewChild('fsbutton') fsbutton;  // the fullscreen button

    subscription: Subscription;
    
    currentContext:any = {
        insName: '',
        username: '',
        group: ''
    };
    classToggled = false;

    constructor(public utl: UtilService, public menu: MenuService, public userblockService: UserblockService, 
        public settings: SettingsService, public router: Router, private messageService: MessageService,
        private sanitizer : DomSanitizer, public session: SessionStorageService) {

        // show only a few items on demo
        this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout

        this.subscription = this.messageService.getMessage().subscribe(res => {
            if (res && res.type == 'changepwdsuccess') {
                this.navToLandingPage();
            }
        });
    }
    public toggleField() {
        this.classToggled = !this.classToggled;  
      }
    ngOnInit() {
        this.isNavSearchVisible = false;

        var authToken = this.utl.session.getObject('authtoken');
        var employee = this.session.getSessionVariable("employee");
        if(authToken.user && authToken.user.institution && authToken.user.institution.name) {
            this.currentContext.insName = authToken.user.institution.name;
        }  
        if(authToken.user) {
            this.currentContext.username = authToken.user.username;
            if(authToken.user.group && authToken.user.group.code) {
                if(authToken.user.group.code == 'staff' && employee) {
                    this.currentContext.username = employee.lastName ? employee.firstName + ' ' + employee.lastName : employee.firstName;
                }
            }
            if(authToken.user.group) {
                this.currentContext.group = authToken.user.group.name;
            }        
        }   

        if(authToken.user.username != "superadmin") {
            this.getLogo();
        }

        // var ua = window.navigator.userAgent;
        // if (ua.indexOf("MSIE ") > 0 || !!ua.match(/Trident.*rv\:11\./)) { // Not supported under IE
        //     this.fsbutton.nativeElement.style.display = 'none';
        // }

        // // Switch fullscreen icon indicator
        // const el = this.fsbutton.nativeElement.firstElementChild;
        // screenfull.on('change', () => {
        //     if (el)
        //         el.className = screenfull.isFullscreen ? 'fa fa-compress' : 'fa fa-expand';
        // });
    }

    toggleUserBlock(event) {
        event.preventDefault();
        this.userblockService.toggleVisibility();
    }

    openNavSearch(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setNavSearchVisible(true);
    }

    setNavSearchVisible(stat: boolean) {
        // console.log(stat);
        this.isNavSearchVisible = stat;
    }

    getNavSearchVisible() { 
        return this.isNavSearchVisible;
    }

    toggleOffsidebar() {
        this.settings.toggleLayoutSetting('offsidebarOpen');
    }

    toggleCollapsedSideabar() {
        this.messageService.sendMessage('toggleSidebar', {});
        this.settings.toggleLayoutSetting('isCollapsed');
    }

    isCollapsedText() {
        return this.settings.getLayoutSetting('isCollapsedText');
    }

    toggleFullScreen(event) {
        if (screenfull.enabled) {
            screenfull.toggle();
        }
    }
    logout() {
        this.utl.nav.clear();
        this.router.navigate(['login']);
    }

    homeClick() {
        let targetRoute = this.utl.session.get('landingroute');
        this.router.navigate([targetRoute]);
    }

    changepassword(){
        this.router.navigate(['/admin/changepassword']);
    }

    navToLandingPage() {
        let landingRoute = this.utl.session.get('landingroute');
        this.router.navigate([landingRoute]);
    }

     //download file
     getLogo() {
        let actionUrl = "admin/preference/getInstituteLogo";
        var options: any = {
            action: actionUrl,
            type: 'get',
            data: null,
            callback: this.getLogoCallback(this.currentContext, this.sanitizer)
        };
        this.utl.http.doDownload(options);
    }

    getLogoCallback(detail, sanitizer) {
        return (res) => {
            var reader = new FileReader();
            reader.readAsDataURL(res); 
            reader.onloadend = function() {
                 let base64Data: any = reader.result;  
                 base64Data = base64Data.replace('data:application/oct-steam;base64', 'data:image/jpeg;base64')  ;
                 detail.imageData = sanitizer.bypassSecurityTrustUrl(base64Data);  
            }
        };
    }

    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.subscription.unsubscribe();
    }
}
