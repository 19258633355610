const Controls = {
    text: 'Controls',
    link: '/superadmin/controls',
    icon: ''
};

const ReferenceGroups = {
    text: 'Reference Groups',
    link: '/admin/referencegroups',
    icon: ''
};

const Clients = {
    text: 'Clients',
    link: '/superadmin/clients',
    icon: ''
};

const Organizations = {
    text: 'Organizations',
    link: '/admin/organizations',
    icon: ''
};

const Institutions = {
    text: 'Institutions',
    link: '/admin/institutions',
    icon: ''
};


const States = {
    text: 'States',
    link: '/configuration/states',
    icon: ''
};

const Districts = {
    text: 'Districts',
    link: '/configuration/districts',
    icon: ''
};

const Cities = {
    text: 'Cities',
    link: '/configuration/cities',
    icon: ''
};

const Countries = {
    text: 'Countries',
    link: '/configuration/countries',
    icon: ''
};

const Nationalities = {
    text: 'Nationality',
    link: '/configuration/nationality',
    icon: ''
};

const Modules = {
    text: 'Modules',
    link: '/admin/modules',
    icon: ''
};

const DefaultTemplates = {
    text: 'Default Templates',
    link: '/admin/defaulttemplates',
    icon: ''
}

const DefaultReports = {
    text: 'Default Reports',
    link: '/admin/defaultreports',
    icon: ''
}

const RptInstitutions = {
    text: 'Company List',
    link: '/rpt/rptinstitutionlist',
    icon: ''
};

const RptLicenseExpiredInstitutions = {
    text: 'License Expired Company',
    link: '/rpt/rptinstitutionlicense',
    icon: ''
};

const ConferenceSummary = {
    text: 'Conference Summary',
    link: '/admin/conferencesummary',
    icon: ''
}

const VideoUpload = {
    text: 'Video Upload',
    link: '/staff/videoupload',
    icon: ''
}

const superAdmin = {
    text: 'Super Admin',
    link: '',
    icon: 'fas fa-user-cog',
    submenu: [
        Clients,
        Organizations,
        Institutions,
        ReferenceGroups,
        Countries,
        States,
        Districts,
        Cities,
        Nationalities,
        Modules,
        Controls,
        DefaultTemplates,
        DefaultReports,
        VideoUpload
    ]
};
const reports = {
    text: 'Reports',
    link: '',
    icon: 'fas fa-clipboard-list',
    submenu: [
        RptInstitutions,
        RptLicenseExpiredInstitutions
    ]
};
const videoconference = {
    text: 'Video Conference',
    link: '',
    icon: 'fas fa-video',
    submenu: [
        ConferenceSummary
    ]
};

export const menu = [
];

export const allmenu = {
    1: [
        superAdmin,
        reports,
        videoconference
    ]
};
